import { MainTitle} from "./ui/Titles"
import '../styles/about.css'
import about_img from '../assets/about.png'
import interactive_image from "../assets/interactive_image.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faHeart, faUser } from "@fortawesome/free-solid-svg-icons";
import { useState } from 'react';
import { AnimatedDiv } from '../components/ui/Animations';


export const AboutContainer = () => {
    return (
        <div className="about_contain">
            <div className="about_presentation">
                <div>
                    <MainTitle title="A propos" />
                    <AnimatedDiv delay={0}>
                    <p className="about_description">
                        Je m'appelle Océane Tapparel et je suis développeuse web. <br/>
                        </p>
                        <p className="about_description">
                        De nature inventive et curieuse, j'apprécie conceptualiser et réaliser des produits qui répondent aux besoins de mes clients, tout en y apportant une touche d'originalité. <br/> </p>
                        </AnimatedDiv>   
                </div>
                <AnimatedDiv delay={0}>
                <img src={about_img} alt="Femme assis à la table de la cuisine" className="img_about" />
                </AnimatedDiv>  
            </div>
            <AnimatedDiv delay={0.5}>
            <p className="about_interactive_text"> Cliquez sur les icônes pour me découvrir davantage </p>
            <InteractiveImage />
            </AnimatedDiv>  

        </div>
    )
}

const InteractiveImage = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupContent, setPopupContent] = useState("");
    const [popupDescription1, setPopupDescription1] = useState("");
    const [popupDescription2, setPopupDescription2] = useState("");
    const [popupDescription3, setPopupDescription3] = useState("");

    const handleIconClick = (iconName, description1, description2, description3) => {
        setPopupContent(`${iconName}`);
        setPopupDescription1(`${description1}`);
        setPopupDescription2(`${description2}`);
        setPopupDescription3(`${description3}`);
        setIsPopupOpen(true);
       

    
    };

    const closeModal = () => {
        setIsPopupOpen(false);
        document.body.classList.remove('no-scroll');

       
    };


    

    return (
        <div className="about_interactive_size">
            <div className="about_interactive_contain"> </div>      
            <div className="about_interactive_contain1"> </div>
        <div className="interactive_contain">
            <img
                src={interactive_image}
                alt="Extérieur avec une femme"
                className="img_interative"
            />
           
          
            {/* Icône 1 */}
            <FontAwesomeIcon
                icon={faStar}
                size="2x"
                className="interactive_icon scale-up icon1"
                style={{ color: "#A6231D" }}
                onClick={() => handleIconClick("Mes projets actuels et futurs", "Mon objectif principal est d'élaborer des sites web fonctionnels et adaptés aux besoins de mes clients.", "Chaque projet est l'occasion d'approfondir ses connaissances et repousser ses limites.", "J'aspire à élargir mon réseau et développer une entreprise durable dans le domaine du numérique, tout en restant à l'écoute des tendances et évolutions du marché.")}
            />

            {/* Icône 2 */}
            <FontAwesomeIcon
                icon={faHeart}
                size="2x"
                className="interactive_icon scale-up icon2"
                style={{ color: "#A6231D" }}
                onClick={() => handleIconClick("Mes valeurs", "La créativité est le fil conducteur de mon parcours. Pour moi, il ne s'agit pas seulement d'apporter des idées nouvelles, mais de voir le monde sous un angle différent, de transformer l'ordinaire en extraordinaire. Chaque projet est une toile vierge, une occasion de donner vie à des concepts innovants et uniques.", "La responsabilité est au coeur de mes engagements. Il est crucial d'assumer avec sérieux et intégrité ses projets et interactions. C'est en prenant pleinement en charge mes actions, que je peux construire des relations de confiance solides et atteindre mes objectifs.", "L'épanouissement est important dans ma vie privée et professionnelle. Chaque jour est une nouvelle opportunité d'apprendre.")}
            />

            {/* Icône 3 */}
            <FontAwesomeIcon
                icon={faUser}
                size="2x"
                className="interactive_icon scale-up icon3"
                style={{ color: "#A6231D" }}
                onClick={() => handleIconClick("Mon parcours", "En parallèle de mon poste de collaboratrice qualité et projets, j'ai obtenu mon bachelor en informatique de gestion à l'HES-SO de Sierre. Ces deux activités m'ont permis d'allier deux mondes très différents mais complémentaires, enrichissant ainsi mon parcours professionnel de manière significative.",
                   "Travailler dans le domaine de la qualité et la réalisation de projets, me donne l'opportunité de développer un sens aigu du détail et de la rigeur, tout en affinant mes compétences en gestion de projets et amélioration continue. En paralèle, mes études ont renforcé ma capacité d'analyse et ma conception de solutions innovantes.", "Cette combinaison unique d'expérience me permets de bâtir un réseau professionnel solide qui continue de s'élargir et de s'enrichir.")}
            />
            {/* Modal */}
            {isPopupOpen && (
                <div className={`popup-overlay ${isPopupOpen ? 'show' : ''}`}>
                    <div className="popup-content">
                        <span className="close-button" onClick={closeModal}>
                            &times;
                        </span>
                        <h4>{popupContent}</h4>
                        <div className="scrollable_content">
                        <p> {popupDescription1} </p>
                        
                        <p> {popupDescription2} </p>
                        <p> {popupDescription3} </p>
                        </div>
                    </div>
                </div>

            )}
        </div>
</div>

    );
};